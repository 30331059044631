import { AccountabilityFramework, Person, Roles, Task } from "../dataModel/dataModel";
import { DragActionProps } from "../dataModel/dragHelpers";
import { useConfiguration } from "../providers/userContext";
import Cell from "./Cell";
import TaskCell from "./TaskCell";


type RowProps = {
    task: Task;
    persons: Person[];
    framework: AccountabilityFramework;
    print: boolean;
    updateTask: (task: Task) => void;
    onDelete: (task: Task) => void;

} & DragActionProps;

const Row = ({ framework, task, persons, dragOver, dragOverLr, onDragStart, onDragOver, onDrop, onDragEnter, updateTask, print, onDelete }: RowProps) => {
    const { configuration } = useConfiguration();
    const cellUpdated = (person: Person, roles: Roles, description?: string) => {

        if (task.assignments.find((a) => a.personId === person.personId) === undefined) {
            task.assignments.push({ personId: person.personId, roles: roles, description });
        } else {
            task.assignments = task.assignments.map(a => {
                if (a.personId === person.personId) {
                    a.roles = roles;
                    a.description = description
                }
                return a;
            });
        }
        updateTask(task);
    }

    const validation = () => {
        if (configuration.validation) {
            const message = framework.validateTask(task);
            if (message.length > 0) {
                return <i className="fi fi-br-triangle-warning" title={message}></i>
            }
        }
    }

    return <tr  key={"ktr" + task.taskId}>
        <TaskCell
            key={"k" + task.taskId}
            task={task}
            print={print}
            dragOver={dragOver}
            dragOverLr={dragOverLr}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            updateTask={updateTask}
            onDelete={onDelete} />

        {
            persons.map((p, i) => {
                const assignment = task.assignments.find(a => a.personId === p.personId);
                return <Cell
                    framework={framework}
                    assignment={assignment ?? { roles: Roles.None, personId: p.personId }}
                    key={"cell" + i}
                    cellUpdated={(roles: Roles, description?: string) => cellUpdated(p, roles, description)} />
            })
        }
        <td className={print ? "hide" : ""}>
            {validation()}
        </td>
    </tr >;
}

export default Row;