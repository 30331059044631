// Cell functional component

import { ChangeEvent, useEffect, useState } from "react";
import { AccountabilityFramework, Assignment, CellStyle, Roles } from "../dataModel/dataModel";
import { useConfiguration } from "../providers/userContext";
import Squares from "./Squares";
import TextCell from "./TextCell";
import EmojiCell from "./EmojiCell";
import Popup from "./Popup";
import NoColorTextCell from "./NoColorTextCell";

type CellProps = {
    assignment?: Assignment;
    cellUpdated: (roles: Roles, description?: string) => void;
    framework: AccountabilityFramework;
}



const Cell = ({ assignment, cellUpdated, framework }: CellProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { configuration } = useConfiguration();
    const [selectedRoles, setSelectedRoles] = useState<Roles>(assignment ? assignment.roles : Roles.None);
    const [description, setDescription] = useState<string | undefined>(assignment ? assignment.description : undefined);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setSelectedRoles(assignment ? assignment.roles : Roles.None);
    }, [assignment, configuration]);


    const handleCheckboxChange = (option: Roles) => {
        const updatedRoles = selectedRoles & option
            ? selectedRoles & ~option
            : selectedRoles | option;
        // console.log(toBits(option));
        // console.log(toBits(selectedRoles));
        // console.log(toBits(selectedRoles & ~option));
        // console.log(toBits(selectedRoles | option));
        // console.log(toBits(updatedRoles));
        if (assignment) {
            if (framework.validateAssignment(updatedRoles)) {
                setSelectedRoles(updatedRoles)
                cellUpdated(updatedRoles, description);

            }
        } else {
            setSelectedRoles(updatedRoles)

        }
    }

    useEffect(() => {
        const onKeyDownHandler = (event: Event): any => {
            console.log(`key pressed: ${(event as any).key}`)
            console.log(event)
            if(event.target && (event.target as any).localName ==="textarea") {
                return;
            }
            const key: string = (event as any).key;
            if (key === "Escape" || key === "Enter") {
                toggleDropdown();
            }
            if (key === "Tab") {
                // TODO toggleDropdown();
            }
            if (event.target && (event.target as any).nodeName !== "INPUT") {
                framework.shortNames.forEach((ch, i) => {
                    if (ch === key.toUpperCase()) {
                        handleCheckboxChange(framework.roles[i]);
                    }
                })
            }
        }
        if (isOpen) {
            document.addEventListener('keydown', onKeyDownHandler);
        } else {
            document.removeEventListener('keydown', onKeyDownHandler);
        }
        return () => {
            document.removeEventListener('keydown', onKeyDownHandler);
        }
        // eslint-disable-next-line
    }, [isOpen, selectedRoles]);

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e !== null && e.target !== null) {
            setDescription((e.target as any).value);
            cellUpdated(selectedRoles, (e.target as any).value)
        }
        e.stopPropagation();
    };

    return <td className={"c " + (description ? "comment" : "")} onDoubleClick={toggleDropdown}
        title={description ? description : "Double-click to edit"}>
        {configuration?.cellStyle === CellStyle.Text && <TextCell roles={assignment ? assignment.roles : Roles.None} framework={framework} />}
        {configuration?.cellStyle === CellStyle.Squares && <Squares roles={assignment ? assignment.roles : Roles.None} framework={framework} />}
        {configuration?.cellStyle === CellStyle.Emojis && <EmojiCell roles={assignment ? assignment.roles : Roles.None} framework={framework} />}
        {configuration?.cellStyle === CellStyle.NoColor && <NoColorTextCell roles={assignment ? assignment.roles : Roles.None} framework={framework} />}


        <Popup isOpen={isOpen} closed={() => {
            toggleDropdown();
        }}>
            <h2>Select roles</h2>
            <div>
                {framework.roles.map((option, i) => (
                    <label key={option} className={framework.names[i].toLowerCase()}>
                        <input
                            type="checkbox"
                            value={i}
                            disabled={!framework.validateAssignment(option | selectedRoles)}
                            checked={(selectedRoles & option) === option}
                            onChange={() => handleCheckboxChange(option)}
                            title={framework.names[i]}
                        />
                        {framework.shortNames[i]}
                    </label>
                ))}
            </div>
            <h3>Comment</h3>
            <div>
                <textarea value={description} onChange={handleInputChange} />
            </div>

            <button onClick={() => {
                toggleDropdown();
            }}>Close</button>
        </Popup>
    </td>
}

export default Cell;