export enum Roles {
    None = 0,
    Responsible = 1 << 0,
    Accountable = 1 << 1,
    Consulted = 1 << 2,
    Informed = 1 << 3,
    Support = 1 << 4,
    Perform = 1 << 5,
    Control = 1 << 6,
    Suggest = 1 << 7,
    Omitted = 1 << 8,
    Verification = 1 << 9,
    SignOff = 1 << 10,
    Participant = 1 << 11,
    Advisor = 1 << 12,
    Driver = 1 << 13,
    Approver = 1 << 14,
    Contributor = 1 << 15,
    Recommend = 1 << 16,
    Agree = 1 << 17,
    Input = 1 << 18,
    Decide = 1 << 19
}



export enum MatrixType {
    Standard, // tasks -> person -> roles
    SingelTask, // persons -> Raci
}

export enum AccountabilityType {
    Raci,
    Rasci,
    Pacsi,
    Cairo,
    RaciVs,
    Arpa,
    Daci,
    Rapid
}

export type AccountabilityFramework = {
    type: AccountabilityType
    name: string,
    roles: Roles[];
    names: string[];
    shortNames: string[];
    help: string[];
    description: string;
    link?: string;
    validateAssignment: (roles: Roles) => boolean;
    validatePerson: (person: Person, tasks: Task[]) => string;
    validateAssignments: (role: Roles, assignments: Assignment[]) => string;
    validateTask: (task: Task) => string;
}

export const Frameworks: AccountabilityFramework[] = [];
Frameworks[AccountabilityType.Raci] = {
    type: AccountabilityType.Raci,
    name: "RACI",
    roles: [Roles.Responsible, Roles.Accountable, Roles.Consulted, Roles.Informed],
    names: ["Responsible", "Accountable", "Consulted", "Informed"],
    shortNames: ["R", "A", "C", "I"],
    validateAssignment: (roles: Roles) => {
        if (roles & Roles.Responsible &&
            (roles & Roles.Consulted || roles & Roles.Informed)) return false;
        if (roles & Roles.Accountable &&
            (roles & Roles.Consulted || roles & Roles.Informed)) return false;
        if (roles & Roles.Informed && roles & Roles.Consulted) return false;
        return true;
    },
    help: [
        "The person or group directly performing the work required for a task or deliverable.",
        "The individual who delegates and reviews the work, ensuring it aligns with project expectations.",
        "Stakeholders providing input and feedback during the project.",
        "Individuals receiving updates on project progress, even if not directly involved in the work."
    ],
    description: "Clarifies roles and responsibilities by designating individuals as Responsible, Accountable, Consulted, or Informed for specific tasks",
    validatePerson: (person: Person, tasks: Task[]): string => {
        // validate person
        if (tasks.length > 5) {
            // only validate if more than 5 tasks
            const NoOfA = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Accountable);
                }).length > 0;
            })
            const NoOfR = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Responsible);
                }).length > 0;
            })
            if (NoOfA.length > tasks.length * 0.8) {
                return "Person has to many A's";
            }
            if (NoOfR.length > tasks.length * 0.8) {
                return "Person has to many R's";
            }

        }
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        if (role === Roles.Accountable) {
            const asses = assignments.filter(a => a.roles & Roles.Accountable);
            if (asses.length === 0) {
                return "No Person has the Accountability role";
            } else if (asses.length > 1) {
                return "Max one person can have the Accountability role";
            }
        }
        if (role === Roles.Responsible) {
            const rs = assignments.filter(a => a.roles & Roles.Responsible);
            if (rs.length === 0) {
                return "No Person has the Responsible role";
            }
        }
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length > 1) {
            return "To many accountable persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length === 0) {
            return "No accountable person";
        }
        if (task.assignments.filter(a => a.roles & Roles.Responsible).length === 0) {
            return "No responsible persons - who does the work?";
        }
        return "";
    }
}

Frameworks[AccountabilityType.Rasci] = {
    type: AccountabilityType.Rasci,
    name: "RASCI",
    roles: [Roles.Responsible, Roles.Accountable, Roles.Support, Roles.Consulted, Roles.Informed],
    names: ["Responsible", "Accountable", "Support", "Consulted", "Informed"],
    shortNames: ["R", "A", "S", "C", "I"],
    validateAssignment: (roles: Roles) => {
        if (roles & Roles.Responsible &&
            (roles & Roles.Consulted || roles & Roles.Informed || roles & Roles.Support)) return false;
        if (roles & Roles.Accountable &&
            (roles & Roles.Consulted || roles & Roles.Informed || roles & Roles.Support)) return false;
        if ((roles & Roles.Informed && roles & Roles.Consulted) ||
            (roles & Roles.Informed && roles & Roles.Support) ||
            (roles & Roles.Support && roles & Roles.Consulted)) return false;
        return true;
    },
    help: [
        "The person or group directly performing the work required for a task or deliverable.",
        "The individual who delegates and reviews the work, ensuring it aligns with project expectations.",
        "Provides assistance, guidance, and expertise to the accountable person during task execution.",
        "Stakeholders providing input and feedback during the project.",
        "Individuals receiving updates on project progress, even if not directly involved in the work."
    ],
    description: "Defines roles using Responsible, Accountable, Supportive, Consulted, and Informed categories.",
    validatePerson: (person: Person, tasks: Task[]): string => {
        // validate person
        if (tasks.length > 5) {
            // only validate if more than 5 tasks
            const NoOfA = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Accountable);
                }).length > 0;
            })
            const NoOfR = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Responsible);
                }).length > 0;
            })
            if (NoOfA.length > tasks.length * 0.8) {
                return "Person has to many A's";
            }
            if (NoOfR.length > tasks.length * 0.8) {
                return "Person has to many R's";
            }

        }
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        if (role === Roles.Accountable) {
            const asses = assignments.filter(a => a.roles & Roles.Accountable);
            if (asses.length === 0) {
                return "No Person has the Accountability role";
            } else if (asses.length > 1) {
                return "Max one person can have the Accountability role";
            }
        }
        if (role === Roles.Responsible) {
            const rs = assignments.filter(a => a.roles & Roles.Responsible);
            if (rs.length === 0) {
                return "No Person has the Responsible role";
            }
        }
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length > 1) {
            return "To many accountable persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length === 0) {
            return "No accountable person";
        }
        if (task.assignments.filter(a => a.roles & Roles.Responsible).length === 0) {
            return "No responsible persons - who does the work?";
        }
        return "";
    }
}

Frameworks[AccountabilityType.Pacsi] = {
    type: AccountabilityType.Pacsi,
    name: "PACSI",
    roles: [Roles.Perform, Roles.Accountable, Roles.Control, Roles.Suggest, Roles.Informed],
    names: ["Perform", "Accountable", "Control", "Suggest", "Informed"],
    shortNames: ["P", "A", "C", "S", "I"],
    validateAssignment: (roles: Roles) => {
        if (roles & Roles.Perform &&
            (roles & Roles.Control || roles & Roles.Informed || roles & Roles.Suggest)) return false;
        if (roles & Roles.Accountable &&
            (roles & Roles.Control || roles & Roles.Informed || roles & Roles.Suggest)) return false;
        if ((roles & Roles.Informed && roles & Roles.Control) ||
            (roles & Roles.Informed && roles & Roles.Suggest) ||
            (roles & Roles.Suggest && roles & Roles.Control)) return false;
        return true;
    },
    help: [
        "The executor who actively carries out the task.",
        "The person answerable for task completion and quality.",
        "Reviews and approves the work, ensuring compliance.",
        "Provides input and recommendations based on expertise",
        "Kept up-to-date on progress but lacks decision-making authority."
    ],
    description: "Focuses on Perform, Accountable, Control, Suggest, and Inform roles in decision-making.",
    validatePerson: (person: Person, tasks: Task[]): string => {
        // validate person
        if (tasks.length > 5) {
            // only validate if more than 5 tasks
            const NoOfA = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Accountable);
                }).length > 0;
            })
            const NoOfP = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Perform);
                }).length > 0;
            })
            if (NoOfA.length > tasks.length * 0.8) {
                return "Person has to many A's";
            }
            if (NoOfP.length > tasks.length * 0.8) {
                return "Person has to many P's";
            }

        }
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        if (role === Roles.Accountable) {
            const asses = assignments.filter(a => a.roles & Roles.Accountable);
            if (asses.length === 0) {
                return "No Person has the Accountability role";
            } else if (asses.length > 1) {
                return "Max one person can have the Accountability role";
            }
        }
        if (role === Roles.Perform) {
            const rs = assignments.filter(a => a.roles & Roles.Perform);
            if (rs.length === 0) {
                return "No Person has the Perform role";
            }
        }
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length > 1) {
            return "To many accountable persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length === 0) {
            return "No accountable person";
        }
        if (task.assignments.filter(a => a.roles & Roles.Perform).length === 0) {
            return "No performer - who does the work?";
        }
        return "";
    }
}

Frameworks[AccountabilityType.Cairo] = {
    type: AccountabilityType.Cairo,
    name: "CAIRO",
    roles: [Roles.Consulted, Roles.Accountable, Roles.Informed, Roles.Responsible, Roles.Omitted],
    names: ["Consulted", "Accountable", "Informed", "Responsible", "Omitted"],
    shortNames: ["C", "A", "I", "R", "O"],
    validateAssignment: (roles: Roles) => {
        if (roles & Roles.Responsible &&
            (roles & Roles.Consulted || roles & Roles.Informed || roles & Roles.Omitted)) return false;
        if (roles & Roles.Accountable &&
            (roles & Roles.Consulted || roles & Roles.Informed || roles & Roles.Omitted)) return false;
        if ((roles & Roles.Informed && roles & Roles.Consulted) ||
            (roles & Roles.Informed && roles & Roles.Omitted) ||
            (roles & Roles.Omitted && roles & Roles.Consulted)) return false;
        return true;
    },
    help: [
        "Provides assistance, guidance, and expertise to the accountable person during task execution.",
        "The individual who delegates and reviews the work, ensuring it aligns with project expectations.",
        "Individuals receiving updates on project progress, even if not directly involved in the work.",
        "The person or group directly performing the work required for a task or deliverable.",
        "Individuals not directly involved in the task.",

    ],
    description: "Expanded version of RACI (aka RACIO) with an Out-of-the-loop role",
    validatePerson: (person: Person, tasks: Task[]): string => {
        // validate person
        if (tasks.length > 5) {
            // only validate if more than 5 tasks
            const NoOfA = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Accountable);
                }).length > 0;
            })
            const NoOfR = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Responsible);
                }).length > 0;
            })
            if (NoOfA.length > tasks.length * 0.8) {
                return "Person has to many A's";
            }
            if (NoOfR.length > tasks.length * 0.8) {
                return "Person has to many R's";
            }

        }
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        if (role === Roles.Accountable) {
            const asses = assignments.filter(a => a.roles & Roles.Accountable);
            if (asses.length === 0) {
                return "No Person has the Accountability role";
            } else if (asses.length > 1) {
                return "Max one person can have the Accountability role";
            }
        }
        if (role === Roles.Responsible) {
            const rs = assignments.filter(a => a.roles & Roles.Responsible);
            if (rs.length === 0) {
                return "No Person has the Responsible role";
            }
        }
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length > 1) {
            return "To many accountable persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length === 0) {
            return "No accountable person";
        }
        if (task.assignments.filter(a => a.roles & Roles.Responsible).length === 0) {
            return "No responsible persons - who does the work?";
        }
        return "";
    }
}
Frameworks[AccountabilityType.RaciVs] = {
    type: AccountabilityType.RaciVs,
    name: "RACI-VS",
    roles: [Roles.Responsible, Roles.Accountable, Roles.Consulted, Roles.Informed, Roles.Verification, Roles.SignOff],
    names: ["Responsible", "Accountable", "Consulted", "Informed", "Verification", "Sign-Off"],
    shortNames: ["R", "A", "C", "I", "V", "S"],
    validateAssignment: (roles: Roles) => {
        if (roles & Roles.Responsible &&
            (roles & Roles.Consulted || roles & Roles.Informed || roles & Roles.SignOff)) return false;
        if (roles & Roles.Accountable &&
            (roles & Roles.Consulted || roles & Roles.Informed || roles & Roles.SignOff)) return false;
        if (roles & Roles.Informed && roles & Roles.Consulted) return false;
        if (roles & Roles.SignOff && roles & Roles.Verification) return false;

        return true;
    },
    help: [
        "The person or group directly performing the work required for a task or deliverable.",
        "The individual who delegates and reviews the work, ensuring it aligns with project expectations.",
        "Stakeholders providing input and feedback during the project.",
        "Individuals receiving updates on project progress, even if not directly involved in the work.",
        "Someone who checks the results of the activities, its deliverables, or outcomes",
        "The individaual who signs off the verification."
    ],
    description: "Enhanced version of the RACI model with the addition of a “Veto” role.",
    validatePerson: (person: Person, tasks: Task[]): string => {
        // validate person
        if (tasks.length > 5) {
            // only validate if more than 5 tasks
            const NoOfA = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Accountable);
                }).length > 0;
            })
            const NoOfR = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Responsible);
                }).length > 0;
            })
            if (NoOfA.length > tasks.length * 0.8) {
                return "Person has to many A's";
            }
            if (NoOfR.length > tasks.length * 0.8) {
                return "Person has to many R's";
            }

        }
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        if (role === Roles.Accountable) {
            const asses = assignments.filter(a => a.roles & Roles.Accountable);
            if (asses.length === 0) {
                return "No Person has the Accountability role";
            } else if (asses.length > 1) {
                return "Max one person can have the Accountability role";
            }
        }
        if (role === Roles.SignOff) {
            const asses = assignments.filter(a => a.roles & Roles.SignOff);
            if (asses.length === 0) {
                return "No Person has the Sign-Off role";
            } else if (asses.length > 1) {
                return "Max one person can have the Sign-Off role";
            }
        }
        if (role === Roles.Responsible) {
            const rs = assignments.filter(a => a.roles & Roles.Responsible);
            if (rs.length === 0) {
                return "No Person has the Responsible role";
            }
        }
        if (role === Roles.Verification) {
            const rs = assignments.filter(a => a.roles & Roles.Verification);
            if (rs.length === 0) {
                return "No Person has the Verification role";
            }
        }
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length > 1) {
            return "To many accountable persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.SignOff).length > 1) {
            return "To many sign-off persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length === 0) {
            return "No accountable person";
        }
        if (task.assignments.filter(a => a.roles & Roles.SignOff).length === 0) {
            return "No Sign-Off person";
        }
        if (task.assignments.filter(a => a.roles & Roles.Responsible).length === 0) {
            return "No responsible persons - who does the work?";
        }
        if (task.assignments.filter(a => a.roles & Roles.Verification).length === 0) {
            return "No persons doing verifications";
        }
        return "";
    }
}
Frameworks[AccountabilityType.Arpa] = {
    type: AccountabilityType.Arpa,
    name: "ARPA",
    roles: [Roles.Accountable, Roles.Responsible, Roles.Participant, Roles.Advisor],
    names: ["Accountable", "Responsible", "Participant", "Advisor"],
    shortNames: ["A", "R", "P", "A"],
    validateAssignment: (roles: Roles) => {
        if (roles & Roles.Responsible &&
            (roles & Roles.Participant || roles & Roles.Advisor)) return false;
        if (roles & Roles.Accountable &&
            (roles & Roles.Participant || roles & Roles.Advisor)) return false;
        if (roles & Roles.Participant && roles & Roles.Advisor) return false;
        return true;
    },
    help: [
        "The individual who delegates and reviews the work, ensuring it aligns with project expectations.",
        "The person or group directly performing the work required for a task or deliverable.",
        "Allocates time to the task and support the responsible.",
        "Consult and advice the responsible and participant, without decision making authority."
    ],
    description: "An alternative to RACI",
    validatePerson: (person: Person, tasks: Task[]): string => {
        // validate person
        if (tasks.length > 5) {
            // only validate if more than 5 tasks
            const NoOfA = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Accountable);
                }).length > 0;
            })
            const NoOfR = tasks.filter(t => {
                return t.assignments.filter(a => {
                    return (a.personId === person.personId && a.roles & Roles.Responsible);
                }).length > 0;
            })
            if (NoOfA.length > tasks.length * 0.8) {
                return "Person has to many A's";
            }
            if (NoOfR.length > tasks.length * 0.8) {
                return "Person has to many R's";
            }

        }
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        if (role === Roles.Accountable) {
            const asses = assignments.filter(a => a.roles & Roles.Accountable);
            if (asses.length === 0) {
                return "No Person has the Accountability role";
            } else if (asses.length > 1) {
                return "Max one person can have the Accountability role";
            }
        }
        if (role === Roles.Responsible) {
            const rs = assignments.filter(a => a.roles & Roles.Responsible);
            if (rs.length === 0) {
                return "No Person has the Responsible role";
            }
        }
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length > 1) {
            return "To many accountable persons";
        }
        if (task.assignments.filter(a => a.roles & Roles.Accountable).length === 0) {
            return "No accountable person";
        }
        if (task.assignments.filter(a => a.roles & Roles.Responsible).length === 0) {
            return "No responsible persons - who does the work?";
        }
        return "";
    }
}
Frameworks[AccountabilityType.Daci] = {
    type: AccountabilityType.Daci,
    name: "DACI",
    roles: [Roles.Driver, Roles.Approver, Roles.Contributor, Roles.Informed],
    names: ["Driver", "Approver", "Contributor", "Informed"],
    shortNames: ["D", "A", "C", "I"],
    validateAssignment: (roles: Roles) => {

        return true;
    },
    help: [
        "The driver is responsible for moving the decision-making process forward, coordinating stakeholders, and ensuring progress.",
        "The approver has the final authority to approve or reject the decision.",
        "Contributors provide expertise and input, but they do not make the final decision.",
        "Individuals who need to be informed about the decision, even though they are not directly involved in making it."
    ],
    description: "Used to clarify decision-making authority with roles like Driver, Approver, Contributor, and Informed",
    validatePerson: (person: Person, tasks: Task[]): string => {
        return "";
    },
    validateAssignments: (role: Roles, assignments: Assignment[]): string => {
        return "";
    },
    validateTask: (task: Task): string => {
        if (task.assignments.length === 0) {
            return "No Assigments";
        }

        if (task.assignments.filter(a => a.roles & Roles.Driver).length === 0) {
            return "No drivers";
        }
        if (task.assignments.filter(a => a.roles & Roles.Approver).length === 0) {
            return "No approvers";
        }
        return "";
    }
}

// Commented out in wait for approval by Bain
// Frameworks[AccountabilityType.Rapid] = {
//     type: AccountabilityType.Rapid,
//     name: "RAPID®️",
//     roles: [Roles.Recommend, Roles.Agree, Roles.Perform, Roles.Input, Roles.Decide],
//     names: ["Recommend", "Agree", "Perform", "Input", "Decide"],
//     shortNames: ["R", "A", "P", "I", "D"],
//     validateAssignment: (roles: Roles) => {

//         return true;
//     },
//     help: [
//         "Make recommendations on how the work should be completed.",
//         "Establish agreement based on recommendations.",
//         "Implements the agreed decision",
//         "Provides advice, the SME.",
//         "Makes the decision and enforces them"

//     ],
//     description: "Tool to agree and assign decisions making, created by Bain & Company",
//     link: "https://www.bain.com/insights/rapid-decision-making/",
//     validatePerson: (person: Person, tasks: Task[]): string => {
//         return "";
//     },
//     validateAssignments: (role: Roles, assignments: Assignment[]): string => {
//         return "";
//     },
//     validateTask: (task: Task): string => {
//         if (task.assignments.length === 0) {
//             return "No Assigments";
//         }

//         if (task.assignments.filter(a => a.roles & Roles.Driver).length === 0) {
//             return "No drivers";
//         }
//         if (task.assignments.filter(a => a.roles & Roles.Approver).length === 0) {
//             return "No approvers";
//         }
//         return "";
//     }
// }


export const roleToText = (type: AccountabilityType, roles: Roles, sep: string) => {
    const fx = Frameworks[type];
    let retVal = "";
    fx.roles.forEach((r, i) => {
        if (roles & r) retVal += fx.shortNames[i]
    })
    return retVal.split("").join(sep);
}

export const roleToTitle = (type: AccountabilityType, roles: Roles, sep: string) => {
    const fx = Frameworks[type];
    let retVal: string[] = [];
    fx.roles.forEach((r, i) => {
        if (roles & r) retVal = retVal.concat(fx.names[i])
    })
    return retVal.join(sep);
}

export const classNames = (type: AccountabilityType, roles: Roles) => {
    const fx = Frameworks[type];
    let retVal = "";
    fx.roles.forEach((r, i) => {
        if (roles & r) retVal += " " + fx.names[i].toLowerCase()
    })
    return retVal;

};


export type Person = {
    name: string;
    order: number;
    personId: string;
    shade?: number;
}

export type Assignment = {
    personId: string;
    roles: Roles;
    description?: string;
}

export type Category = {
    name: string;
    order: number;
    categoryId: string;
    collapsed?: boolean;
    description?: string;
}

export type Task = {
    name: string;
    taskId: string;
    description?: string;
    categoryId?: string;
    order: number;
    assignments: Assignment[];
}
export type VersionedModel = {
    version: number;
}
export type MatrixModel = VersionedModel & {
    title: string;
    description?: string;
    tasks: Task[];
    persons: Person[];
    categories: Category[];
    type: AccountabilityType;
    matrixType: MatrixType;
    version: 2;
}


// just how to thinkg about versions
export type MatrixModel_v3 = MatrixModel & {
    version: 3;
}

export enum CellStyle {
    Text,
    Squares,
    Emojis,
    NoColor
}
export enum HeadingStyle {
    Default, // angeled
    Straight // straight
}

export type Configuration = {
    roleSeparator: string;
    cellStyle: CellStyle;
    grid: boolean;
    validation: boolean;
    headingStyle: HeadingStyle;
}