import { AccountabilityFramework, Roles, roleToTitle } from "../dataModel/dataModel";
type SquaresProps = {
    roles: Roles,
    framework: AccountabilityFramework
}
const Squares = ({ roles, framework }: SquaresProps) => {
    return <div className="squareHost"  title={roleToTitle(framework.type, roles, " & ")}>
        {roles & Roles.Responsible ? <div className="square-sm responsible">R</div> : <div className="square"> </div>}
        {roles & Roles.Accountable ? <div className="square-sm accountable">A</div> : <div className="square"> </div>}
        {roles & Roles.Consulted ? <div className="square-sm consulted">C</div> : <div className="square"> </div>}
        {roles & Roles.Informed ? <div className="square-sm informed">I</div> : <div className="square"> </div>}
    </div>
}


export default Squares;