import Logo from "../logo.svg";

type FooterProps = {
    print: boolean;
}

const Footer = ({ print }: FooterProps) => {
    return<div>
    < footer ><div className={print ? "hide" : ""}>Made with passion by <a href="https://www.wictorwilen.se" target="_blank" rel="noreferrer">Wictor Wilén</a></div>
        <div className={print ? "" : "hide"}><img src={Logo} alt="raci.app" className="logo" /> Made with raci.app</div>
    </footer >
</div >
}

export default Footer;