import { ReactNode, useEffect, useRef } from "react";

type PopupProps = {
    children: ReactNode;
} & { isOpen: boolean, closed: () => void };

const Popup = ({ children, isOpen, closed }: PopupProps) => {

    const ref = useRef<any>(null);

    useEffect(() => {
        // Handle outside click
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closed();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [isOpen, closed]);

    return <>
        {isOpen && (
            <div className="dd dlg" ref={ref} draggable="false">
                {children}
            </div>
        )}</>
}
export default Popup;