import { AccountabilityFramework, Category, Person, Task, roleToText } from "../dataModel/dataModel";
import commonRole from "../helpers/commonRole";
import CategoryCell from "./CategoryCell";

type CategoryRowProps = {
    category: Category;
    framework: AccountabilityFramework;
    dragOver: string | undefined;
    dragOverLr: string | undefined;
    print: boolean;
    persons: Person[];
    tasks: Task[];
    onDragStart: (e: any) => any;
    onDragOver: (e: any) => any;
    onDrop: (e: any) => any;
    onDragEnter: (e: any) => any;
    updateCategory: (category: Category) => void;
    onDelete: (category: Category) => void;
    addTaskToCategory: () => void;
}

const CategoryRow = ({ framework, persons, category, tasks, dragOver, dragOverLr, onDragStart, onDragOver, onDrop, onDragEnter, updateCategory, print, onDelete, addTaskToCategory }: CategoryRowProps) => {

    return <tr  key={"tr" + category.categoryId}>
        <CategoryCell
            key={category.categoryId}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            category={category}
            addTaskToCategory={addTaskToCategory}
            dragOver={dragOver}
            dragOverLr={dragOverLr}
            onDelete={onDelete}
            print={print}
            updateCategory={updateCategory}
        />
        {persons.map(p => {
            return <td className="catc" title="Most common role in this category" key={category.categoryId + "-" + p.personId}><div>{roleToText(framework.type, commonRole(
                tasks
                    .filter(t => t.categoryId === category.categoryId)
                    .map(t => {
                        return t.assignments.filter(a => a.personId === p.personId).map(a => {
                            return a.roles
                        })
                    }).flat()
            ), "")}</div></td>
        })}

    </tr >;
}

export default CategoryRow;