import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Category } from "../dataModel/dataModel";
import { DragActionProps } from "../dataModel/dragHelpers";
import Popup from "./Popup";

type CategoryCellProps = {
    category: Category;
    updateCategory: (category: Category) => void;
    print: boolean;
    onDelete: (category: Category) => void;
    addTaskToCategory: (category: Category) => void;

} & DragActionProps;

const CategoryCell = ({ category, onDragEnter, onDragOver, onDragStart, onDrop, dragOver, dragOverLr, print, updateCategory, addTaskToCategory, onDelete }: CategoryCellProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(category.name);
    const [description, setDescription] = useState(category.description);
    const ref = useRef<HTMLInputElement>(null);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = (e: any) => {
        setName(e.target.value);
    };

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e !== null && e.target !== null) {
            setDescription((e.target as any).value);
        }
        e.stopPropagation();
    };

    useEffect(() => {
        updateCategory({ ...category, name, description })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name,description]);

    useEffect(() => {
        if (ref && ref.current) ref.current.focus();
    }, [ref, isOpen])

    useEffect(()=> {
        setName(category.name);
        setDescription(category.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return <td
        draggable
        id={"category-" + category.categoryId}
        className={"nowrap cat " + (dragOver === category.categoryId ? "drag-over-" + dragOverLr : "") + (description ? " comment" : "")}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
    >

        <div className='editable-text' title={description} >
            {(!print && category.collapsed === true) && <i className="fi fi-br-angle-small-right" onClick={() => updateCategory({ ...category, collapsed: false })}></i>}
            {(!print && category.collapsed !== true) && <i className="fi fi-br-angle-small-down" onClick={() => updateCategory({ ...category, collapsed: true })}></i>}
            <span onDoubleClick={togglePopup}>{category.name}</span>
            {addTaskToCategory &&
                <i className={"fi fi-br-plus-small hand " + (print ? " hide" : "")} onClick={() => { addTaskToCategory(category) }} ></i>}
        </div>
        <Popup isOpen={isOpen} closed={() => {
            togglePopup();
        }}>
            <h2>Edit category</h2>
            <div>
                <input ref={ref} type="text" value={name} style={{ width: "300px" }} onChange={handleInputChange} />
            </div>
            <h3>Comment</h3>
            <div>
                <textarea value={description} onChange={handleTextAreaChange} />
            </div>
            <button onClick={() => {
                togglePopup();
            }}>Close</button> {onDelete &&
                <i className="fi fi-br-trash" onClick={() => { togglePopup(); onDelete(category) }}></i>
            }
        </Popup>

    </td>
}

export default CategoryCell;