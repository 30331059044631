import React, { useEffect, useRef, useState } from 'react';
type EditableTextProps = {
    initialValue: string;
    multiline?: boolean;
    onConfirm: (value: string) => void;
    onDelete?: () => void;
    onAdd?: () => void;
    title?: string;
    print: boolean;
};
const EditableText = ({ initialValue, onConfirm, title, onDelete, onAdd, multiline, print }: EditableTextProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [textValue, setTextValue] = useState<string | undefined>(initialValue);
    const ref = useRef<HTMLInputElement>(null);
    const refta = useRef<HTMLTextAreaElement>(null);

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    useEffect(() => {
        if (isEditing) {
            if (ref && ref.current) ref.current.select();
            if(refta && refta.current) refta.current.focus();
        }
    }, [isEditing]);

    const handleInputChange = (e: any) => {
        setTextValue(e.target.value);
    };

    const handleInputKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
            onConfirm(textValue ?? "");
        }
    };

    const handleInputKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            setIsEditing(false);
            setTextValue(initialValue);
        }
    };

    useEffect(() => {
        setTextValue(initialValue);
    }, [initialValue]);

    return (
        <div className='editable-text' title={title}>
            {isEditing ? (
                <>
                    {multiline && <textarea value={textValue}
                        onChange={handleInputChange}
                        ref={refta}
                        onKeyPress={handleInputKeyPress}
                        onKeyDown={handleInputKeyDown}
                        autoFocus />}
                    {!multiline && <input
                        type="text"
                        value={textValue}
                        onChange={handleInputChange}
                        ref={ref}
                        onKeyPress={handleInputKeyPress}
                        onKeyDown={handleInputKeyDown}
                        autoFocus
                    />}
                    <i className="fi fi-br-check" onClick={() => {
                        setIsEditing(false);
                        onConfirm(textValue ?? "");
                    }}></i>
                    {onDelete &&
                        <i className="fi fi-br-trash" onClick={() => { setTextValue(undefined); setIsEditing(false); onDelete() }}></i>
                    }
                </>
            ) : (
                <>
                    {/* <i className={"fi fi-br-pencil grey" + (print? " hide": "")}></i> */}
                    <span onDoubleClick={handleDoubleClick}>{textValue}</span>
                    {onAdd &&
                        <i className={"fi fi-br-plus-small hand " + (print ? " hide" : "")} onClick={onAdd} ></i>}

                </>
            )}
        </div>
    );
};

export default EditableText;