import { AccountabilityFramework, Roles, classNames, roleToTitle } from "../dataModel/dataModel";
import { useConfiguration } from "../providers/userContext";
type EmojiCellProps = {
    roles: Roles,
    framework: AccountabilityFramework
}


const roleToText = (roles: Roles, sep: string) => {
    let retVal = "";
    if ((roles & Roles.Responsible) ||
        (roles & Roles.Perform)) retVal = "🛠️";
    if (roles & Roles.Accountable) retVal = "👑";
    if (roles & Roles.Consulted) retVal = "🗨️";
    if (roles & Roles.Informed) retVal = "📢";
    if (roles & Roles.Omitted) retVal = "🚫";
    if (roles & Roles.Support) retVal = "🤝";
    if (roles & Roles.Control) retVal = "🎛️";
    if (roles & Roles.Suggest) retVal = "📝";

    if ((roles & Roles.Responsible && roles & Roles.Accountable) ||
        (roles & Roles.Perform && roles & Roles.Accountable)) retVal = "🚀";
    return retVal;
}


const EmojiCell = ({ roles, framework }: EmojiCellProps) => {
    const { configuration } = useConfiguration();
   
    return <div className={"square-m" + classNames(framework.type, roles)}  title={roleToTitle(framework.type, roles, " & ")}>
        {roleToText(roles, configuration!.roleSeparator)}
    </div>
}


export default EmojiCell;