import React, { useEffect, useState } from 'react';

import './App.css';
import Matrix from './components/Matrix';
import { AccountabilityType, MatrixModel, MatrixType, VersionedModel } from './dataModel/dataModel';
import { UserProvider } from './providers/userContext';
import RaciCookieConsent from './components/RaciCookieConsent';
// import defaultData from './dataModel/default.json';
import { useCookies } from 'react-cookie';

import axios from "axios";
import Modal from './components/Modal';
import { nanoid } from 'nanoid';
import WhatsNew, { CURRENT_VERSION } from './components/WhatsNew';

const demoModel = {
    "tasks": [
        {
            "name": "Define project scope",
            "order": 0,
            "taskId": "OqqLQiMZrre6MwCkRkNnj",
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "yRku76BZ99skH1CmXZrog",
                    "roles": 1
                },
                {
                    "personId": "GYGZPi7lzU6AcFS0U3qTB",
                    "roles": 2
                },
                {
                    "personId": "POP3Rz_mhp_iiEGyqpDPz",
                    "roles": 4
                },
                {
                    "personId": "r2R-TeMhDjQdEQS8ZLSuz",
                    "roles": 8
                }
            ],
            "categoryId": "yE99PqEnUOjBT7XrUlOdp"
        },
        {
            "name": "Gather requirements",
            "order": 1,
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "yRku76BZ99skH1CmXZrog",
                    "roles": 2
                },
                {
                    "personId": "hkGNvUJUX_Xp67QtVpp38",
                    "roles": 1
                },
                {
                    "personId": "POP3Rz_mhp_iiEGyqpDPz",
                    "roles": 4
                },
                {
                    "personId": "BdDzTMstAqW_FKEpf8l7W",
                    "roles": 8
                }
            ],
            "taskId": "S-wB-sixKaSF_J0RulVIi",
            "categoryId": "yE99PqEnUOjBT7XrUlOdp"
        },
        {
            "name": "Design user interface",
            "order": 2,
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "yRku76BZ99skH1CmXZrog",
                    "roles": 2
                },
                {
                    "personId": "a8NitCEyZUB7W-MupGfxf",
                    "roles": 1
                },
                {
                    "personId": "ewycyfx2edPvGoH35r_UJ",
                    "roles": 1
                },
                {
                    "personId": "SL68awD-vQ-hd2-K0ICpP",
                    "roles": 4
                }
            ],
            "taskId": "Q6ScAsXOg2df4gmwyeDz9",
            "categoryId": "yE99PqEnUOjBT7XrUlOdp"
        },
        {
            "name": "Develop backend",
            "order": 0,
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "yRku76BZ99skH1CmXZrog",
                    "roles": 2
                },
                {
                    "personId": "SL68awD-vQ-hd2-K0ICpP",
                    "roles": 1
                },
                {
                    "personId": "ewycyfx2edPvGoH35r_UJ",
                    "roles": 4
                }
            ],
            "taskId": "yr9d8v8udZfD-BiecZCK7",
            "categoryId": "ELqElGGBL4V4czF42Ey3O"
        },
        {
            "name": "Create test cases",
            "order": 1,
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "hqmYYKikAFaGthvoimsR_",
                    "roles": 3
                },
                {
                    "personId": "ewycyfx2edPvGoH35r_UJ",
                    "roles": 0
                },
                {
                    "personId": "a8NitCEyZUB7W-MupGfxf",
                    "roles": 8
                }
            ],
            "taskId": "EPwnHyZTSENtD_pitlaYI",
            "categoryId": "ELqElGGBL4V4czF42Ey3O"
        },
        {
            "name": "Conduct user testing",
            "order": 2,
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "yRku76BZ99skH1CmXZrog",
                    "roles": 2
                },
                {
                    "personId": "hqmYYKikAFaGthvoimsR_",
                    "roles": 1
                },
                {
                    "personId": "a8NitCEyZUB7W-MupGfxf",
                    "roles": 4
                }
            ],
            "taskId": "C-6w0et9_yrb3VqdSbTBT",
            "categoryId": "ELqElGGBL4V4czF42Ey3O"
        },
        {
            "name": "Deploy to production",
            "order": 0,
            "assignments": [
                {
                    "personId": "9NJnnflW8B9vNgcyOD0QH",
                    "roles": 8
                },
                {
                    "personId": "yRku76BZ99skH1CmXZrog",
                    "roles": 2
                },
                {
                    "personId": "BdDzTMstAqW_FKEpf8l7W",
                    "roles": 1
                },
                {
                    "personId": "r2R-TeMhDjQdEQS8ZLSuz",
                    "roles": 4
                },
                {
                    "personId": "GYGZPi7lzU6AcFS0U3qTB",
                    "roles": 8
                }
            ],
            "taskId": "1KNEXdJ7iaNnFfKaZE8sa",
            "categoryId": "T43YMoojknKqOHOEyfhTI"
        }
    ],
    "persons": [
        {
            "name": "Project Sponsor",
            "order": 0,
            "personId": "GYGZPi7lzU6AcFS0U3qTB"
        },
        {
            "name": "Project Manager",
            "order": 1,
            "personId": "yRku76BZ99skH1CmXZrog"
        },
        {
            "name": "Business Analyst",
            "order": 2,
            "personId": "hkGNvUJUX_Xp67QtVpp38"
        },
        {
            "name": "UX Designer",
            "order": 3,
            "personId": "a8NitCEyZUB7W-MupGfxf"
        },
        {
            "name": "Backend Developer",
            "order": 4,
            "personId": "SL68awD-vQ-hd2-K0ICpP"
        },
        {
            "name": "QA Analyst",
            "order": 5,
            "personId": "hqmYYKikAFaGthvoimsR_"
        },
        {
            "name": "Devops Engineer",
            "order": 6,
            "personId": "BdDzTMstAqW_FKEpf8l7W"
        },
        {
            "name": "Frontend team",
            "order": 8,
            "personId": "ewycyfx2edPvGoH35r_UJ"
        },
        {
            "name": "Team Members",
            "order": 9,
            "personId": "POP3Rz_mhp_iiEGyqpDPz"
        },
        {
            "name": "IT Operations",
            "order": 10,
            "personId": "r2R-TeMhDjQdEQS8ZLSuz"
        },
        {
            "name": "Stakeholders",
            "order": 11,
            "personId": "9NJnnflW8B9vNgcyOD0QH"
        }
    ],
    "categories": [
        {
            "name": "Design",
            "categoryId": "yE99PqEnUOjBT7XrUlOdp",
            "order": 0
        },
        {
            "name": "Develop",
            "categoryId": "ELqElGGBL4V4czF42Ey3O",
            "order": 1
        },
        {
            "name": "Deploy",
            "categoryId": "T43YMoojknKqOHOEyfhTI",
            "order": 2
        }
    ],
    "title": "Sample RACI",
    "description": "Use the toolbar to create a new matrix, and then doubleclick to edit rows and columns and drag and drop to reorder",
    "type": 0,
    "version": 2,
    "matrixType": 0
} as MatrixModel;

const App = () => {
    const [loading, setLoading] = useState(false);
    const [matrix, setMatrix] = useState<MatrixModel>(demoModel);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showWhatsNew, setShowWhatsNew] = useState(false);
    const [cookies, setCookie] = useCookies(['version']);
    useEffect(() => {
        if (!loading && document.location.hash && document.location.hash.length > 0) {
            setLoading(true);
            const hash = document.location.hash.replace("#", "");
            if (hash.startsWith("new-")) {
                const arr = hash.split("-");
                if (arr.length === 2) {
                    try {
                        const type: AccountabilityType = AccountabilityType[arr[1] as keyof typeof AccountabilityType];
                        if (type !== undefined) {
                            console.log(type)
                            setMatrix({
                                tasks: [{ name: "Task 1", order: 0, assignments: [], taskId: nanoid() }],
                                persons: [{ name: "Stakeholder 1", order: 0, personId: nanoid() }],
                                categories: [],
                                title: "New Matrix",
                                description: undefined,
                                matrixType: MatrixType.Standard,
                                type: type,
                                version: 2
                            });
                        }
                        setLoading(false)
                    } catch (err) {
                        console.error(`Invalid Accountability Type: {0}`, hash)
                    }
                }
            } else {
                axios
                    .get("/api/share?id=" + hash, {
                        validateStatus: () => true
                    })
                    .then((r: any) => {
                        if (r.status === 200) {
                            if ((r.data as VersionedModel).version === 1) {
                                alert("This is an old version, no longer supported")
                            } else {
                                setMatrix(r.data)
                                console.log(r.data)
                                setLoading(false)
                            }
                        } else if (r.status === 404) {
                            setLoading(false);
                            setError("Matrix not found, or has automatically been consumed by the void");
                        } else {
                            setLoading(false);
                            setError("Unexpected error");
                        }
                    })

            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(cookies.version === undefined || (cookies.version && cookies.version < CURRENT_VERSION)) {
            setShowWhatsNew(true);
        }
    },[cookies.version]);
    return (
        <div className="App">
            <UserProvider>
                <Matrix matrix={matrix} />
                <Modal isOpen={loading} closed={() => { }}>Loading shared matrix...</Modal>
                <Modal isOpen={error !== undefined} closed={() => { }}>{error} <button onClick={() => { setError(undefined); }} >Ok!</button></Modal>
                <WhatsNew show={showWhatsNew} onClose={function (): void {
                    setShowWhatsNew(false);
                    const expiry = new Date();
                    expiry.setTime(expiry.getTime() + 1000*60*60*24*28); // 28 days expiry
                    setCookie("version", CURRENT_VERSION,{
                        expires: expiry
                    });
                }} />

            </UserProvider>
            <RaciCookieConsent></RaciCookieConsent>
        </div>
    );
}

export default App;
