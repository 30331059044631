import { useEffect, useState } from "react";
type ToggleIconProps = {
    name: string;
    title: string;
    onToggled: (enabled: boolean) => void;
};
const ToggleIcon = ({ name, onToggled, title }: ToggleIconProps) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        onToggled(enabled);
    }, [enabled, onToggled]);
    return <i className={"fi fi-br-" + name + " " + (enabled ? "selected" : "")} onClick={() => setEnabled(!enabled)} title={title}></i>
}

export default ToggleIcon;