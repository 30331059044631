import { AccountabilityFramework, Roles, classNames, roleToText, roleToTitle } from "../dataModel/dataModel";
import { useConfiguration } from "../providers/userContext";
export type TextCellProps = {
    roles: Roles;
    framework: AccountabilityFramework;
}

const TextCell = ({ roles, framework }: TextCellProps) => {
    const {configuration} = useConfiguration();

    return <div className={"square-m" + classNames(framework.type, roles)} title={roleToTitle(framework.type, roles, " & ")}>
        {roleToText(framework.type, roles, configuration.roleSeparator)}
    </div>
}


export default TextCell;