import React, { ReactNode, createContext, useContext, useState } from 'react';
import { CellStyle, Configuration, HeadingStyle } from '../dataModel/dataModel';


type ConfigurationContextType = {
    configuration: Configuration;
    updateConfiguration: (newConfig: Partial<Configuration>) => void;
  };
  
const UserContext = createContext<ConfigurationContextType | undefined>(undefined);

type UserProviderProps = {
    children: ReactNode;
};


export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [configuration, setConfig] = useState<Configuration>({
        cellStyle: CellStyle.Text,
        roleSeparator: "/",
        grid: true,
        validation: true,
        headingStyle: HeadingStyle.Default
    });

    const updateConfiguration = (newConfig: Partial<Configuration>) => {
        setConfig({ ...configuration, ...newConfig });
    };

    const contextValue: ConfigurationContextType = {
        configuration,
        updateConfiguration,
      };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
}

export function useConfiguration() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useConfiguration must be used within a UserProvider");
    }
    return context;
}