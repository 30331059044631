import { AccountabilityType, Frameworks } from "../dataModel/dataModel";
import Modal from "./Modal";

type NewModalProps = {
    show: boolean;
    onClose: (framework?: AccountabilityType) => void
}
export const CURRENT_VERSION = 2;
const NewModal = ({ show, onClose }: NewModalProps) => {
    return <Modal isOpen={show} closed={() => { onClose() }}>
        <div>
            <h2>Create a new matrix</h2>
            <div>Are you sure you want to create a new matrix? Existing matrix will be lost.</div>
            <ul className="fxlist">
                {Frameworks.map((f, i) => {
                    return <li value={i} onClick={(e) => { onClose(f.type) }} key={"new-" + i}>
                        <div><h3>{f.name}</h3><span>{f.description}</span></div>
                    </li>
                })}
            </ul>
            <button onClick={(e)=> {onClose(undefined)}}>Close</button>
        </div>
    </Modal >
}

export default NewModal;