// Cell functional component


import { AccountabilityFramework, CellStyle,  Roles } from "../dataModel/dataModel";
import { useConfiguration } from "../providers/userContext";

import Squares from "./Squares";
import TextCell from "./TextCell";
import EmojiCell from "./EmojiCell";
import NoColorTextCell from "./NoColorTextCell";

type SingleCellProps = {
    role: Roles;
    framework: AccountabilityFramework;
    cellUpdated: (role: Roles) => void;
}

const SingleCell = ({ framework, role, cellUpdated }: SingleCellProps) => {
    const { configuration } = useConfiguration();

    return <td className="c" onDoubleClick={() => { cellUpdated(role); }} title={"Double-click to edit"}>
        {configuration?.cellStyle === CellStyle.Text && <TextCell roles={role} framework={framework} />}
        {configuration?.cellStyle === CellStyle.Squares && <Squares roles={role} framework={framework} />}
        {configuration?.cellStyle === CellStyle.Emojis && <EmojiCell roles={role} framework={framework} />}
        {configuration?.cellStyle === CellStyle.NoColor && <NoColorTextCell roles={role} framework={framework} />}
    </td>
}

export default SingleCell;