// src/components/CookieConsent.js
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const RaciCookieConsent = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept and continue"
            cookieName="raci-app-cookie"
            style={{ background: '#c0c0c0', fontSize: 16, color: '#000' }}
            disableButtonStyles={true}
            declineButtonText="Decline"
            enableDeclineButton={true}
            onDecline={()=>{
                (window as any).gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                  });
            }}
            expires={150}>
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
};

export default RaciCookieConsent;