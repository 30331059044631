
import { AccountabilityType, Category, CellStyle, Frameworks, HeadingStyle, MatrixModel, MatrixType, Person, Task } from "../dataModel/dataModel";
import Row from "./Row";
import { useCallback, useEffect, useRef, useState } from "react";
import Logo from "../logo.svg";
import { useConfiguration } from "../providers/userContext";
import EditableText from "./EditableText";
import ToggleIcon from "./ToggleIcon";
import ToggleStateIcon from "./ToggleStateIcon";
import axios from "axios";

import domtoimage from 'dom-to-image-more';
import Modal from "./Modal";
import { nanoid } from "nanoid";
import Footer from "./Footer";
import Legend from "./Legend";
import AboutModal from "./AboutModal";
import PersonRow from "./PersonRow";
import OptionsDlg from "./OptionsDlg";
import HelpModal from "./HelpModal";
import CategoryRow from "./CategoryRow";
import NewModal from "./NewModal";


type MatrixProps = {
    matrix: MatrixModel;
}

const Matrix = ({ matrix }: MatrixProps) => {

    const [dragOver, setDragOver] = useState("");
    const [dragOverLr, setDragOverLr] = useState("");
    const [legend, showLegend] = useState(false);
    const [persons, setPersons] = useState<Person[]>([]);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [matrixType, setMatrixType] = useState<MatrixType>(matrix.matrixType);
    const [description, setDescription] = useState<string | undefined>(matrix.description);
    const [title, setTitle] = useState<string>(matrix.title);
    const [type, setType] = useState<AccountabilityType>(matrix.type);
    const [draggedPerson, setDraggedPerson] = useState<Person | undefined>(undefined);
    const [draggedTask, setDraggedTask] = useState<Task | undefined>(undefined);
    const [draggedCategory, setDraggedCategory] = useState<Category | undefined>(undefined);
    const { configuration, updateConfiguration } = useConfiguration();
    const [print, setPrint] = useState(false);
    const [showClear, setShowClear] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const [savedMatrices, setSavedMatrices] = useState<MatrixModel[]>([]);
    const [selected, setSelected] = useState<number | undefined>(undefined);
    const [showHelp, setShowHelp] = useState(false);
    const [sharing, setSharing] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [showLoad, setShowLoad] = useState(false);
    const [sharingUrl, setSharingUrl] = useState<string | undefined>(undefined);
    const [sharingError, setSharingError] = useState<string | undefined>(undefined);
    const [showStyles, setShowStyles] = useState(false);
    const [selectedTask, setSelectedTask] = useState(0);
    const [fx, setFx] = useState(Frameworks[matrix.type]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [zoom, setZoom] = useState(false);


    const [dragType, setDragType] = useState<string | undefined>(undefined);


    const handleDragStart = (e: any) => {
        const { id } = e.target;

        if (id.startsWith("person-")) {
            setDragType("person");
            const person = persons.find(p => "person-" + p.personId === id);
            if (person) {
                setDraggedPerson(person);
            }
        } else if (id.startsWith("task-")) {
            setDragType("task");
            const task = tasks.find(t => "task-" + t.taskId === id);
            if (task) {
                setDraggedTask(task);
            }
        } else if (id.startsWith("category-")) {
            setDragType("category")
            const category = categories.find(c => "category-" + c.categoryId === id);
            if (category) {
                setDraggedCategory(category);
            }
        }
    };
    const handleDragOver = (e: any) => e.preventDefault();

    useEffect(() => {
        setMatrixType(matrix.matrixType);
        setTitle(matrix.title);
        setDescription(matrix.description);
        setType(matrix.type);
        setCategories(matrix.categories ?? []);
        setFx(Frameworks[matrix.type]);
    }, [matrix]);

    const handleDragEnter = (e: any) => {
        let element = e.target;
        let id = element.id;
        while (id === undefined || id === "") {
            element = element.parentElement;
            id = element.id;
        }
        switch (dragType) {
            case "task":
                if (id.startsWith("task-")) {
                    const droppedTask = tasks.find(t => "task-" + t.taskId === id);
                    if (droppedTask && draggedTask) {
                        setDragOver(droppedTask.taskId)
                        if (droppedTask.order > draggedTask.order) {
                            setDragOverLr("b");
                        } else {
                            setDragOverLr("t");
                        }
                    }
                } else if (id.startsWith("category-")) {
                    const droppedCategory = categories.find(c => "category-" + c.categoryId === id);
                    if (droppedCategory && draggedTask) {
                        setDragOver(droppedCategory.categoryId)
                        setDragOverLr("b");
                    }
                }
                break;
            case "person":
                if (id.startsWith("person-")) {
                    const droppedPerson = persons.find(p => "person-" + p.personId === id);
                    if (draggedPerson && droppedPerson) {
                        setDragOver(droppedPerson.personId);
                        if (droppedPerson.order > draggedPerson.order) {
                            if (matrixType === MatrixType.Standard) {
                                setDragOverLr("r");
                            } else {
                                setDragOverLr("b")
                            }
                        } else {
                            if (matrixType === MatrixType.Standard) {
                                setDragOverLr("l");
                            } else {
                                setDragOverLr("t");
                            }
                        }
                    }
                }
                break;
            case "category":
                if (id.startsWith("category-")) {
                    const droppedCategory = categories.find(c => "category-" + c.categoryId === id);
                    if (droppedCategory && droppedCategory) {
                        setDragOver(droppedCategory.categoryId)
                        setDragOverLr("b");
                    }
                } else if (id.startsWith("task-")) {
                    const droppedTask = tasks.find(t => "task-" + t.taskId === id);
                    if (droppedTask && categories && droppedTask.categoryId === undefined) {
                        // find the first category and place it before that one
                        const first = categories.sort((a, b) => a.order - b.order)[0];
                        if (first) {
                            setDragOver(first.categoryId);
                            setDragOverLr("t")
                        }
                    } else if (draggedCategory && droppedTask && categories && droppedTask.categoryId) {
                        const cat = categories.find(c => c.categoryId === droppedTask.categoryId);
                        if (cat) {
                            setDragOver(cat.categoryId);
                            setDragOverLr("b")
                        }
                    }
                }
                break;
        }
    };

    const handleOnDrop = (e: any) => {
        let element = e.target;
        let id = element.id;
        while (id === undefined || id === "") {
            element = element.parentElement;
            id = element.id;
        }
        switch (dragType) {
            case "task":
                if (id.startsWith("task-")) {
                    const droppedTask = tasks.find(t => "task-" + t.taskId === id);
                    if (droppedTask && draggedTask) {
                        if (draggedTask.categoryId !== droppedTask.categoryId) {
                            // new category
                            // draggedTask.categoryId = droppedTask.categoryId;
                            // draggedTask.order = droppedTask.order;
                            // droppedTask.order++;
                            setTasks(tasks.map(t => {
                                if (t.taskId === draggedTask.taskId) {
                                    return { ...draggedTask, categoryId: droppedTask.categoryId, order: droppedTask.order };
                                } else if (t.taskId === droppedTask.taskId) {
                                    return { ...droppedTask, order: droppedTask.order + 1 };
                                }
                                if (t.categoryId === droppedTask.categoryId && t.order >= droppedTask.order) {
                                    return { ...t, order: t.order + 1 }
                                }
                                if (t.categoryId === draggedTask.categoryId && t.order > draggedTask.order) {
                                    return { ...t, order: t.order - 1 }
                                }
                                return t;
                            }))

                        } else {
                            // same category
                            const droppedIdx = droppedTask.order;
                            const draggedIdx = draggedTask.order;
                            setTasks(tasks.map(p => {
                                if (p.categoryId === droppedTask.categoryId) {
                                    if (draggedIdx > droppedIdx) {
                                        if (p.order === draggedIdx) {
                                            p.order = droppedIdx;
                                        } else if (p.order >= droppedIdx && p.order < draggedIdx) {
                                            p.order++;
                                        }
                                    } else {
                                        if (p.order === draggedIdx) {
                                            p.order = droppedIdx;
                                        } else if (p.order <= droppedIdx && p.order > draggedIdx) {
                                            p.order--;
                                        }
                                    }
                                }
                                return p;
                            })
                            );


                        }
                    }
                } else if (id.startsWith("category-")) {
                    const droppedCategory = categories.find(c => "category-" + c.categoryId === id);
                    if (droppedCategory && draggedTask) {

                        setTasks(tasks.map(t => {
                            if (t.taskId === draggedTask.taskId) {
                                return { ...draggedTask, order: 0, categoryId: droppedCategory.categoryId };
                            }
                            if (t.categoryId === droppedCategory.categoryId) {
                                return { ...t, order: t.order + 1 }
                            }
                            if (t.categoryId === draggedTask.categoryId && t.order > draggedTask.order) {
                                return { ...t, order: t.order - 1 }
                            }
                            return t;
                        }));

                    }
                }
                break;
            case "category":
                if (id.startsWith("task-") && draggedCategory) {
                    const droppedTask = tasks.find(t => "task-" + t.taskId === id);
                    if (droppedTask && droppedTask.categoryId === undefined) {
                        // dropping a cateogry on a task without category
                        // move first
                        setCategories(categories.map(c => {
                            if (c.categoryId === draggedCategory.categoryId) {
                                return { ...draggedCategory, order: 0 }
                            }
                            if (c.order < draggedCategory.order) {
                                return { ...c, order: c.order + 1 }
                            }
                            return c;
                        }))
                    } else if (droppedTask && droppedTask.categoryId) {
                        // dropping a cateogry on a task with category
                        const droppedCategory = categories.find(c => c.categoryId === droppedTask.categoryId);
                        if (droppedCategory && droppedCategory.categoryId !== draggedCategory.categoryId) {
                            console.log("upadte ordewr")
                            if (draggedCategory.order > droppedCategory.order) console.log("larger")
                            setCategories(categories.map(c => {
                                if (c.categoryId === draggedCategory.categoryId) {
                                    return { ...draggedCategory, order: droppedCategory.order }
                                }
                                if (draggedCategory.order > droppedCategory.order) {
                                    // move before

                                    if (c.order < draggedCategory.order && c.order >= droppedCategory.order) {
                                        return { ...c, order: c.order + 1 }
                                    }
                                } else {
                                    if (c.order > draggedCategory.order && c.order <= droppedCategory.order) {
                                        return { ...c, order: c.order - 1 }
                                    }
                                }

                                return c;
                            }))
                        }
                    }
                } else if (id.startsWith("category-") && draggedCategory) {
                    // NOTE: Same as above
                    const droppedCategory = categories.find(c => "category-" + c.categoryId === id);
                    if (droppedCategory && droppedCategory.categoryId !== draggedCategory.categoryId) {
                        console.log("upadte ordewr")
                        if (draggedCategory.order > droppedCategory.order) console.log("larger")
                        setCategories(categories.map(c => {
                            if (c.categoryId === draggedCategory.categoryId) {
                                return { ...draggedCategory, order: droppedCategory.order }
                            }
                            if (draggedCategory.order > droppedCategory.order) {
                                // move before

                                if (c.order < draggedCategory.order && c.order >= droppedCategory.order) {
                                    return { ...c, order: c.order + 1 }
                                }
                            } else {
                                if (c.order > draggedCategory.order && c.order <= droppedCategory.order) {
                                    return { ...c, order: c.order - 1 }
                                }
                            }

                            return c;
                        }))
                    } else {
                        console.log("SAME")
                    }
                }
                break;
            case "person":
                const droppedPerson = persons.find(p => "person-" + p.personId === id);
                if (droppedPerson && draggedPerson) {
                    const droppedIdx = droppedPerson.order;
                    const draggedIdx = draggedPerson.order;

                    const newPersons = persons.map(p => {

                        if (draggedIdx > droppedIdx) {
                            if (p.order === draggedIdx) {
                                p.order = droppedIdx;
                            } else if (p.order >= droppedIdx && p.order < draggedIdx) {
                                p.order++;
                            }
                        } else {
                            if (p.order === draggedIdx) {
                                p.order = droppedIdx;
                            } else if (p.order <= droppedIdx && p.order > draggedIdx) {
                                p.order--;
                            }
                        }
                        return p;
                    })
                    setPersons(newPersons);

                }
                break;
        }

        setDragOver("");
        setDraggedPerson(undefined);
        setDraggedCategory(undefined)
        setDraggedTask(undefined);
        setDragType(undefined)
    }

    const addPerson = useCallback(() => {
        const newPersons = persons.concat({ name: "Stakeholder " + (persons.length + 1), order: persons.length, personId: nanoid() });
        setPersons(newPersons);

    }, [persons]);

    const addTask = useCallback((category: Category | undefined) => {
        const newTasks = tasks.concat({
            name: "Task " + (tasks.length + 1),
            order: tasks.length,
            assignments: [],
            taskId: nanoid(),
            categoryId: category ? category.categoryId : undefined
        });

        setTasks(newTasks);

    }, [tasks]);

    const addCategory = useCallback(() => {
        const newCategories = (categories ?? []).concat({ name: "Category " + categories.length, categoryId: nanoid(), order: categories.length });
        setCategories(newCategories);
    }, [categories]);

    const updateCategory = useCallback((updated: Category) => {
        setCategories(categories.map(c => {
            if (c.categoryId === updated.categoryId) {
                return updated;
            }
            return c;
        }))
    }, [categories])

    const updateTask = useCallback((updatedTask: Task) => {
        const newTasks = tasks.map(t => t.taskId === updatedTask.taskId ? updatedTask : t);
        setTasks(newTasks);
    }, [tasks]);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // this is the reload case
        setPersons(matrix.persons);
        setTasks(matrix.tasks);
    }, [matrix]);

    const createMatrixFromCurrentState = () => {
        return { tasks, persons, categories, title, description, type, version: 2, matrixType } as MatrixModel;
    }

    return <div className={"m " + (zoom ? "zoom" : "")}>
        <div className="toolbar">
            <a href="https://raci.app"><img src={Logo} alt="raci.app" className="logo" /></a>
            <div className="tools">
                <i className="fi fi-br-add-document" title="New matrix" onClick={() => {
                    setShowClear(true);
                }}></i>
                <i className="fi fi-br-folder-upload" title="Save locally" onClick={() => { setShowSave(true); }}></i>
                <i className="fi fi-br-folder-download" title="Load locally stored matrix" onClick={() => {
                    const saves = window.localStorage.getItem("raci.app-saves");
                    if (saves) {
                        setSavedMatrices(JSON.parse(saves));
                    } else {
                        setSavedMatrices([]);
                    }
                    if ((window as any).gtag) (window as any).gtag("event", "load", {});
                    setShowLoad(true);
                }}></i>
                <i> </i>
                <i> </i>
                <i className="fi fi-br-share" title="Share" onClick={() => {
                    setSharingError(undefined);
                    setSharingUrl(undefined);
                    setSharing(true);
                }}></i>
                <i className="fi fi-br-copy-alt" title="Copy to clipboard" onClick={() => {
                    if (ref != null && ref.current != null) {
                        setPrint(true);
                        navigator.clipboard.write([new ClipboardItem({ "text/html": new Blob([ref.current.outerHTML], { type: "text/html" }) })]);
                        setPrint(false);
                        if ((window as any).gtag) (window as any).gtag("event", "share", { "type": "html" });
                    }
                }}></i>

                <i className="fi fi-br-copy-image" title="Copy image to clipboard" onClick={() => {
                    if (ref != null && ref.current != null) {
                        setPrint(true);
                        domtoimage
                            .toPng(ref.current)
                            .then(function (dataUrl: string) {
                                var img = new Image();
                                img.src = dataUrl;
                                img.style.position = 'absolute';
                                img.style.top = '-10000px'; // Negative value to move above the visible window
                                img.style.left = '-10000px'; // Negative value to move left of the visible window
                                document.body.appendChild(img);
                                setPrint(false);
                                domtoimage.toBlob(img).then((blob: any) => {
                                    navigator.clipboard.write([new ClipboardItem({ "image/png": blob })])
                                    img.remove();
                                    if ((window as any).gtag) (window as any).gtag("event", "share", { "type": "image" });
                                }).catch((err: any) => {
                                    setError(err);
                                })

                            })
                            .catch(function (error: any) {
                                setError(error);
                            });
                    }
                }}></i>
                <i> </i>
                {/* <i className="fi fi-br-check"></i>
                <i> </i> */}
                <ToggleIcon name="books" onToggled={(e) => {
                    showLegend(e);
                }} title="Show Legend" />
                <i className={"fi fi-br-border-all " + (configuration.grid ? "selected" : "")}
                    onClick={() => {
                        updateConfiguration({
                            grid: !configuration.grid
                        });
                    }} title="Show grid"></i>
                <i className={"fi fi-br-graphic-style " + (showStyles ? "selected" : "")}
                    onClick={() => {
                        setShowStyles(!showStyles);
                    }} title="Cell style"></i>

                <i className={"fi fi-br-check-circle " + (configuration.validation ? "selected" : "")}
                    onClick={() => {
                        updateConfiguration({
                            validation: !configuration.validation
                        });
                    }} title="Use matrix validation rules"></i>
                <ToggleStateIcon iconNameOn="zoom-out" iconNameOff="zoom-in" title="Zoom" onToggled={(x) => {
                    setZoom(x);
                }} />
                <i> </i>
                <i> </i>
                <i className="fi fi-br-interrogation" title="Help" onClick={() => { setShowHelp(true); }}></i>
                <i className="fi fi-br-info" onClick={() => { setShowAbout(true); }} title="About"></i>
            </div>
        </div>
        <OptionsDlg isOpen={showStyles} closed={() => { }}><h2>Select style</h2>

            <div>
                <label>
                    Cell style:
                    <select onChange={(ev) => {
                        updateConfiguration({ cellStyle: CellStyle[CellStyle[ev.target.value as any as number] as keyof typeof CellStyle] });
                    }}>
                        <option value={CellStyle.Text} selected={configuration.cellStyle === CellStyle.Text}>Colored text</option>
                        <option value={CellStyle.NoColor} selected={configuration.cellStyle === CellStyle.NoColor}>No colors text</option>
                        <option value={CellStyle.Squares} selected={configuration.cellStyle === CellStyle.Squares}>Squares</option>
                        <option value={CellStyle.Emojis} selected={configuration.cellStyle === CellStyle.Emojis}>Emojis</option>
                    </select>

                </label>
            </div>
            <div>
                <label>
                    Headings:
                    <select onChange={(ev) => {
                        updateConfiguration({ headingStyle: HeadingStyle[HeadingStyle[ev.target.value as any as number] as keyof typeof HeadingStyle] });
                    }}>
                        <option value={HeadingStyle.Default} selected={configuration.headingStyle === HeadingStyle.Default}>Default</option>
                        <option value={HeadingStyle.Straight} selected={configuration.headingStyle === HeadingStyle.Straight}>Classic</option>
                    </select>

                </label>
            </div>
            <div>
                <label>
                    Matrix style:
                    <select onChange={(ev) => {
                        setMatrixType(MatrixType[MatrixType[ev.target.value as any as number] as keyof typeof MatrixType]);
                    }}>
                        <option value={MatrixType.Standard} selected={matrixType === MatrixType.Standard}>Multiple tasks</option>
                        <option value={MatrixType.SingelTask} selected={matrixType === MatrixType.SingelTask}>Single task</option>
                    </select>
                </label>
            </div>
            <div><button onClick={() => {
                setShowStyles(false);
            }}>Done</button></div>
        </OptionsDlg>
        <div className="container">
            <div className="export" ref={ref} >
                <div className="exportPadding">
                    <NewModal show={showClear} onClose={(frwk?: AccountabilityType) => {
                        if (frwk !== undefined) {
                            setTasks([{ name: "Task 1", order: 0, assignments: [], taskId: nanoid() }]);
                            setPersons([{ name: "Person 1", order: 0, personId: nanoid() }]);
                            setTitle(`New ${Frameworks[frwk].name} Matrix`);
                            setDescription(Frameworks[frwk].description);
                            setMatrixType(MatrixType.Standard);
                            setCategories([]);
                            setType(frwk);
                            setFx(Frameworks[frwk]);
                            if ((window as any).gtag) (window as any).gtag("event", "new", { "matrix": AccountabilityType[frwk] });
                        }
                        setShowClear(false)
                    }
                    } />
                    <AboutModal show={showAbout} onClose={() => { setShowAbout(false); }} />
                    <HelpModal show={showHelp} onClose={() => { setShowHelp(false) }} />

                    <Modal isOpen={error !== undefined} closed={() => { }}>
                        <h2>Error</h2>
                        <div style={{ color: "red" }}>{error}</div>
                        <div><button onClick={() => { setError(undefined) }}>Close</button></div>
                    </Modal>

                    <Modal isOpen={showSave} closed={() => { }}>
                        <h2>Save matrix locally</h2>
                        <div>This will store your matrix locally in your browser. It will overwrite any existing stored matrices with the same name.</div>
                        <div><button onClick={() => {
                            const saves = window.localStorage.getItem("raci.app-saves");
                            if (saves) {
                                let j: MatrixModel[] = JSON.parse(saves);
                                const existing = j.find(m => m.title === title);
                                if (existing) {
                                    j = j.map(m => {
                                        if (m.title === title) {
                                            return createMatrixFromCurrentState();
                                        } else {
                                            return m;
                                        }
                                    })
                                } else {
                                    j = j.concat(createMatrixFromCurrentState());
                                }
                                window.localStorage.setItem("raci.app-saves", JSON.stringify(j));
                            } else {
                                window.localStorage.setItem("raci.app-saves", JSON.stringify([createMatrixFromCurrentState()]));
                            }
                            if ((window as any).gtag) (window as any).gtag("event", "save", {});
                            setShowSave(false);
                        }}>Save</button>
                            <button onClick={() => { setShowSave(false) }}>Cancel</button></div>
                    </Modal>
                    <Modal isOpen={showLoad} closed={() => { }}>
                        <h2>Load</h2>
                        <div><label>Select matrix to use. Will replace existing matrix:
                            <select onChange={(ev) => {
                                setSelected(ev.target.value as any as number);
                            }}>
                                <option value="">Select matrix</option>
                                {savedMatrices.map((m, i) => {
                                    return <option value={i} key={"load" + i}>{m.title}</option>
                                })}
                            </select>
                        </label>
                        </div>
                        <div><button onClick={() => {
                            if (selected) {
                                const m = savedMatrices[selected];
                                setTasks(m.tasks ?? []);
                                setPersons(m.persons ?? []);
                                setTitle(m.title);
                                setDescription(m.description);
                                setMatrixType(m.matrixType);
                                setType(m.type);
                                setCategories(m.categories ?? []);
                                setFx(Frameworks[m.type]);
                                setShowLoad(false);
                            }
                        }} disabled={!selected} >Load</button>
                            <button onClick={() => { setShowLoad(false) }}>Close</button></div>
                    </Modal>
                    <Modal isOpen={sharing} closed={() => { }}>
                        <h2>Sharing your Matrix</h2>
                        <p>When you're sharing your matrix anyone with the link can access it.</p>
                        {!sharingUrl && <><p>Do you want to continue?</p> <p ><button onClick={() => {
                            axios.put("/api/share",
                                createMatrixFromCurrentState(), {
                                validateStatus: () => true
                            }).then(r => {
                                switch (r.status) {
                                    case 201:
                                        setSharingUrl(r.headers["location"]);
                                        if ((window as any).gtag) (window as any).gtag("event", "share", { "type": "link" });
                                        break;
                                    case 401:
                                        setSharingError("Access denied");
                                        break;
                                    case 413:
                                        setSharingError("Matrix is to large");
                                        break;
                                    case 415:
                                        setSharingError("Matrix format invalid");
                                        break;
                                    default:
                                        setSharingError(r.statusText);
                                        break;
                                }

                            });
                        }}>Yes</button><button onClick={() => { setSharing(false) }} >No</button></p>
                        </>}
                        {sharingUrl && <p>Use this URL to share your matrix:<br /> <input style={{ width: "80%" }} value={sharingUrl} /></p>}
                        {sharingUrl && <p><button onClick={() => {
                            setSharing(false);
                            window.navigator.clipboard.writeText(sharingUrl);
                            document.location.href = sharingUrl;
                            setSharingUrl(undefined);
                        }}>OK, and copy URL to clipboard</button></p>}
                        {sharingError && <p>Ouch, something happened ({sharingError})</p>}

                    </Modal>
                    <h1 className="title"><EditableText print={print} initialValue={title} onConfirm={(text) => {
                        setTitle(text);
                    }} /></h1>
                    <div className="desc"><EditableText print={print} initialValue={description ?? "Add description"}
                        onConfirm={(text) => {
                            setDescription(text);
                        }}
                        onDelete={() => { setDescription(undefined) }}
                        multiline={true} /></div>

                    {matrixType === MatrixType.SingelTask && <div>
                        <label className={print ? "hide" : ""}>Task:
                            <select onChange={(ev) => {
                                setSelectedTask(ev.target.value as any as number);
                            }}>
                                {tasks.map((t, i) => {
                                    return <option value={i} selected={selectedTask === i} key={"t" + t.taskId}>{t.name}</option>
                                })}
                            </select>
                        </label>
                        <div className={!print ? "hide" : "fnt"}>Task: <b>{tasks.length !== 0 ? tasks[selectedTask].name : "..."}</b></div>
                    </div>
                    }
                    <table className={"table " + (configuration.grid ? "grid" : "")}>
                        <thead>
                            <tr className="r">
                                <th></th>
                                {matrixType === MatrixType.Standard &&
                                    persons.sort((a, b) => a.order - b.order).map((p, i) => {
                                        return <th
                                            className={(configuration.headingStyle === HeadingStyle.Straight ? "heading-s" : "heading-a") + (dragOver === p.personId ? "drag-over-" + dragOverLr : "")}
                                            key={"th-" + p.personId}
                                            id={"person-" + p.personId}
                                            draggable
                                            onDragStart={handleDragStart}
                                            onDragOver={handleDragOver}
                                            onDrop={handleOnDrop}
                                            onDragEnter={handleDragEnter}
                                        >
                                            <EditableText initialValue={p.name} title="Double-click to edit" print={print}
                                                onConfirm={(text) => {
                                                    const newPersons = persons.map(ps => ps.personId === p.personId ? { name: text, order: p.order, personId: p.personId } : ps);
                                                    setPersons(newPersons);
                                                }}
                                                onDelete={() => {
                                                    const newPersons = persons.filter(_ => {
                                                        return _.personId !== p.personId
                                                    });
                                                    setPersons(newPersons);
                                                    const newTasks = tasks.map((t) => {
                                                        return { ...t, assignments: t.assignments.filter(a => a.personId !== p.personId) }
                                                    });
                                                    setTasks(newTasks);
                                                }} />
                                        </th>
                                    })
                                }
                                {matrixType === MatrixType.Standard && <th onClick={addPerson} className={(print ? "hide" : "") + " " + (configuration.headingStyle === HeadingStyle.Straight ? "heading-s" : "heading-a")} title="Add a new person"><div><i className="fi fi-br-plus-small hand"></i></div></th>}
                                {matrixType === MatrixType.SingelTask && fx.shortNames.map((x, i) => {
                                    return <th className={"heading-short "}><div title={fx.names[i]}>{x}</div></th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {matrixType === MatrixType.Standard &&
                                [
                                    // no category or category not found
                                    tasks
                                        .filter(t => t.categoryId === undefined || categories.find(c => c.categoryId === t.categoryId) === undefined)
                                        .sort((a, b) => a.order - b.order)
                                        .map((task, i) => {
                                            return <Row
                                                task={task}
                                                framework={fx}
                                                print={print}
                                                dragOver={dragOver}
                                                dragOverLr={dragOverLr}
                                                onDragStart={handleDragStart}
                                                onDragOver={handleDragOver}
                                                onDrop={handleOnDrop}
                                                onDragEnter={handleDragEnter}
                                                persons={persons.sort((a, b) => a.order - b.order)}
                                                updateTask={updateTask}
                                                onDelete={(taskToDelete) => {
                                                    const newTasks = tasks.filter(t => t.taskId !== taskToDelete.taskId);
                                                    setTasks(newTasks);
                                                }}
                                                key={"row-" + task.taskId} />;
                                        }),

                                    categories.sort((a, b) => a.order - b.order).map((cat, j) => {
                                        return [
                                            <CategoryRow
                                                key={"row+" + cat.categoryId}
                                                category={cat}
                                                framework={fx}
                                                print={print}
                                                persons={persons}
                                                tasks={tasks}
                                                dragOver={dragOver}
                                                dragOverLr={dragOverLr}
                                                onDragStart={handleDragStart}
                                                onDragOver={handleDragOver}
                                                onDrop={handleOnDrop}
                                                onDragEnter={handleDragEnter}
                                                updateCategory={updateCategory}
                                                onDelete={() => {
                                                    setCategories(categories.filter(c => c.categoryId !== cat.categoryId));
                                                    let noOfNoCats = tasks.filter(t => t.categoryId === undefined).length;
                                                    setTasks(tasks.map(t => {
                                                        if (t.categoryId === cat.categoryId) {
                                                            return { ...t, categoryId: undefined, order: noOfNoCats++ }
                                                        }
                                                        return t;
                                                    }))
                                                    console.log(tasks);
                                                }}
                                                addTaskToCategory={() => {
                                                    updateCategory({ ...cat, collapsed: false })
                                                    addTask(cat);
                                                }}
                                            />,
                                            tasks
                                                .filter(t => t.categoryId === cat.categoryId && cat.collapsed !== true)
                                                .sort((a, b) => a.order - b.order)
                                                .map((task, i) => {
                                                    return <Row
                                                        task={task}
                                                        framework={fx}
                                                        print={print}
                                                        dragOver={dragOver}
                                                        dragOverLr={dragOverLr}
                                                        onDragStart={handleDragStart}
                                                        onDragOver={handleDragOver}
                                                        onDrop={handleOnDrop}
                                                        onDragEnter={handleDragEnter}
                                                        persons={persons.sort((a, b) => a.order - b.order)}
                                                        updateTask={updateTask}
                                                        onDelete={(taskToDelete) => {
                                                            const newTasks = tasks.filter(t => t.taskId !== taskToDelete.taskId);
                                                            setTasks(newTasks);
                                                        }}
                                                        key={"row-" + task.taskId} />;
                                                })]
                                    })
                                ]

                            }
                            {matrixType === MatrixType.Standard && <tr className={print ? "hide" : ""}>
                                <td key="taskadd">
                                    <i className="fi fi-br-plus-small hand" onClick={() => { addTask(undefined); }} title="Add a new task (without category)"></i>
                                    <i> </i>
                                    <i className="fi fi-br-layer-plus hand" onClick={() => { addCategory() }} title="Add a new category"></i>
                                </td>
                                {persons.sort((a, b) => a.order - b.order).map((p) => {
                                    if (configuration.validation) {
                                        const message = fx.validatePerson(p, tasks);
                                        if (message.length > 0) {
                                            return <td key={"keyval" + p.personId} title={message}><i className="fi fi-br-triangle-warning"></i></td>;
                                        }
                                    }
                                    return <td key={"keyval" + p.personId}  ></td>;
                                })}</tr>}
                            {matrixType === MatrixType.SingelTask && persons.sort((a, b) => a.order - b.order).map((p) => {
                                return <PersonRow
                                    person={p}
                                    framework={fx}
                                    task={tasks[selectedTask]} // temporary - only the first task
                                    print={print}
                                    dragOver={dragOver}
                                    dragOverLr={dragOverLr}
                                    onDragStart={handleDragStart}
                                    onDragOver={handleDragOver}
                                    onDrop={handleOnDrop}
                                    onDragEnter={handleDragEnter}
                                    updatePerson={(updatedPerson) => {
                                        const newPersons = persons.map(p => p.personId === updatedPerson.personId ? updatedPerson : p);
                                        setPersons(newPersons);
                                    }}
                                    onDelete={(personToDelete) => {
                                        const newPersons = persons.filter(p => p.personId !== personToDelete.personId);
                                        setPersons(newPersons);
                                    }}
                                    updateTask={updateTask}
                                    key={"row-" + p.order} />;
                            })
                            }
                            {matrixType === MatrixType.SingelTask && <tr onClick={addPerson} className={print ? "hide" : ""} title="Add a new person">
                                <td key="personadd"><i className="fi fi-br-plus-small hand"></i></td>
                                {
                                    fx.roles.map(role => {
                                        if (configuration.validation) {
                                            const message = fx.validateAssignments(role, tasks[selectedTask].assignments);
                                            if (message.length > 0) {
                                                return <td key={"keyval" + role} title={message}><i className="fi fi-br-triangle-warning"></i></td>;
                                            }
                                        }
                                        return <td key={"keyval" + role}></td>
                                    })}
                            </tr>}
                        </tbody>
                    </table>

                    {legend && <Legend type={type} />}
                    <Footer print={print} />
                </div>
            </div>
        </div>
    </div >

}

export default Matrix;

