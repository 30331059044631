import { ReactNode, useEffect, useRef } from "react";

type ModalProps = {
    children: ReactNode;
} & { isOpen: boolean, closed: () => void };

const Modal = ({ children, isOpen, closed }: ModalProps) => {

    const ref = useRef<any>(null);


    useEffect(() => {
        // Handle outside click
        const handleClickOutside = (event: any) => {
            if (ref.current === event.target) {
                closed();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [isOpen, closed]);

    return <>
        {isOpen && (
            <div className="modal" ref={ref}>
                <div className="modal-content dlg" draggable="false">
                    {children}
                </div>
            </div>
        )}</>
}
export default Modal;