import { Roles } from "../dataModel/dataModel";

const commonRole = (assignments: Roles[]): Roles => {
    const count = Object.keys(Roles).length / 2;
    const countArray = new Array<number>(count).fill(0);
    assignments.forEach((a, ix) => {
        for(let i = 0; i < count; i++) {
            if(a & (1 << i)) {
                countArray[i]++
            }
        }
    });
    if(countArray.reduce( (a,b)=> a+b) === 0) {
        return Roles.None;
    }
    const max = countArray.indexOf(Math.max(...countArray));

    return (1 << max) as Roles;
    // for (let j = 0; j < b.length; j++) { for (let i = 0; i < 4; i++) { console.log(b[j] & (1 << i)); if ((b[j] & (1 << i))) c[i]++ } }; c; c.indexOf(Math.max(...c))
}

export default commonRole;