import { AccountabilityType, Frameworks } from "../dataModel/dataModel";

type LegendProps = {
    type: AccountabilityType
}
const Legend = ({ type }: LegendProps) => {
    const fx = Frameworks[type];
    return <legend className="legend">
        {
            fx.roles.map((role, i) => {
                return <div>
                    <div className={"square-m " + fx.names[i].toLowerCase()}>{fx.shortNames[i]}</div> 
                    <div style={{paddingLeft: "5px"}}> <b>{fx.names[i]}</b> {fx.help[i]}</div>
                </div>
            })
        }
    </legend>
}

export default Legend;