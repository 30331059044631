import { useEffect, useState } from "react";
type ToggleStateIconProps = {
    iconNameOn: string;
    iconNameOff: string;
    title: string;
    onToggled: (enabled: boolean) => void;
};
const ToggleStateIcon = ({ iconNameOn, iconNameOff, onToggled, title }: ToggleStateIconProps) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        onToggled(enabled);
    }, [enabled, onToggled]);
    return <i className={"fi fi-br-"  + (enabled ? iconNameOn : iconNameOff)} onClick={() => setEnabled(!enabled)} title={title}></i>
}

export default ToggleStateIcon;