import { useState } from "react";
import Modal from "./Modal";

type WhatsNewProps = {
    show: boolean;
    onClose: () => void
}
export const CURRENT_VERSION = 5;
const WhatsNew = ({ show, onClose }: WhatsNewProps) => {
    const [showOld, setShowOld] = useState(false);
    
    return <Modal isOpen={show} closed={() => { onClose() }}>
        <div style={{ textAlign: "left" }}>
            <h2>What's New</h2>
            <h3>2024-03-28</h3>
            <p>
                <ul>
                    <li>Improved editing of Categories and Tasks</li>
                    <li>Added comments/descriptors to both Categories and Tasks</li>
                </ul>
            </p>
            <h3>2024-03-27</h3>
            <p>
                <ul>
                    <li>Changed angle of headings to make it more readable. Old style can be access via the style configuration (called classic)</li>
                    <li>Collapsible categories</li>
                    <li>Added the dominant role in the category summarization</li>
                </ul>
            </p>
            <h3>2024-03-26</h3>
            <p>
                <ul>
                    <li>New "create new" experience </li>
                    <li>Fixed an issue where using the keyboard shortcuts in a cell caused validation errors</li>
                </ul>
            </p>
            <h2 className="hand" onClick={()=>setShowOld(!showOld)}>Older <i className={"fi fi-br-caret-" + (showOld ? "up": "down")}></i></h2>
            <div style={{display: showOld ? "": "none"}}>
                <h3>2024-03-22</h3>
                <p>
                    <ul>
                        <li>Added support for the DACI decision making framework. Access it via the New option</li>
                        <li>Added this What's new dialog!</li>
                    </ul>
                </p>
                <h3>2024-03-21</h3>
                <p>
                    <ul>
                        <li>Added support adding comments to assignments. Double click as usual to edit assignment and then write your comment. Commented assignments will have a red triangle in the corner and you can see the comment by hovering over the cell.</li>
                    </ul>
                </p>
            </div>
            <button onClick={onClose}>OK</button></div>
    </Modal>
}

export default WhatsNew;