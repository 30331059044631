import Modal from "./Modal";

type HelpModalProps = {
    show: boolean;
    onClose: ()=> void
}

const HelpModal = ({ show, onClose }: HelpModalProps) => {

    return <Modal isOpen={show} closed={() => { onClose()}}>
        <h2>Help</h2>
        <div style={{ minWidth: 300 }}>
            <p><i className="fi fi-br-add-document"></i> - Create a new matrix, and choose wich framework to use. Deletes the existing matrix.</p>
            <p><i className="fi fi-br-folder-upload"></i> - Stores the matrix locally in your browser.</p>
            <p><i className="fi fi-br-folder-download"></i> - Loads the matrix from your local browser stroage.</p>
            <p><i className="fi fi-br-share"></i> - Persists the matrix and creates a sharing URL. Anyone with direct access to the URL will be able to see it.</p>
            <p><i className="fi fi-br-copy-alt"></i> - Copies the matrix to the clipboard. Useful for pasting into a rich text format editor.</p>
            <p><i className="fi fi-br-copy-image"></i> - Creates an image and copies to the clipboard.</p>
            <p><i className="fi fi-br-books"></i> - Shows and hides the legend.</p>
            <p><i className="fi fi-br-border-all"></i> - Shows and hides cell borders.</p>
            <p><i className="fi fi-br-graphic-style"></i> - Configuration options for the matrix. Choose cell styles and choose between focusing on one or multiple tasks.</p>
            <p><i className="fi fi-br-check-circle"></i> - Enables and disables matrix rule validations. Validation errors will be seen as yellow warning signs.</p>
            <p><i className="fi fi-br-interrogation"></i> - This help dialog.</p>
            <p><i className="fi fi-br-info"></i> - About raci.app.</p>
            
        </div>
        <button onClick={onClose}>OK</button>
    </Modal>
}

export default HelpModal;