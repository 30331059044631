import { useCookies } from "react-cookie";
import Modal from "./Modal";
import { CURRENT_VERSION } from "./WhatsNew";


type AboutModalProps = {
    show: boolean;
    onClose: () => void
}

const AboutModal = ({ show, onClose }: AboutModalProps) => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(['version']);
    return <Modal isOpen={show} closed={() => { onClose() }}>
        <h2>About</h2>
        <div style={{ minWidth: 300 }}>

            <p>Are you tired of project confusion, overlapping responsibilities, and unclear roles? Look no further! <b>raci.app</b> is here to streamline your project management process.</p>
            <p>The RACI matrix (Responsible, Accountable, Consulted, and Informed) is a powerful tool that clarifies roles and responsibilities within a project. It assigns specific individuals or groups to key project tasks, ensuring everyone knows their part in achieving success.</p>
            <p>Built with love and passion by <a href="https://www.wictorwilen.se" target="_blank" rel="noreferrer">Wictor Wilén</a>, 2024</p>
            <p>UIcons by <a href="https://www.flaticon.com/uicons" target="_blank" rel="noreferrer">Flaticon</a></p>
            <p>
                For feedback, ideas or support please contact <a href="mailto:feedback@raci.app">feedback@raci.app</a>.
            </p>
            <p className="hand" onClick={() => {
                removeCookie("version")
            }}>What's new?</p>
        </div>


        <div>
            <i className="fi fi-br-trash-xmark" style={{ cursor: " pointer" }} onClick={() => {
                if (window.confirm("Are you sure?")) window.localStorage.removeItem("raci.app-saves");
                removeCookie("version");
            }}></i> Clear the local browser storage [v{CURRENT_VERSION}]
        </div>
        <button onClick={onClose}>OK</button>
    </Modal>
}

export default AboutModal;