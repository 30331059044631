import { roleToText, roleToTitle } from "../dataModel/dataModel";
import { useConfiguration } from "../providers/userContext";
import { TextCellProps } from "./TextCell";


const NoColorTextCell = ({ roles, framework }: TextCellProps) => {
    const {configuration} = useConfiguration();

    return <div className={"square-m nc"} title={roleToTitle(framework.type, roles, " & ")}>
        {roleToText(framework.type, roles, configuration.roleSeparator)}
    </div>
}


export default NoColorTextCell;