import { AccountabilityFramework, Assignment, Person, Roles, Task } from "../dataModel/dataModel";
import EditableText from "./EditableText";
import SingleCell from "./SingleCell";


type PersonRowProps = {
    task: Task;
    framework: AccountabilityFramework,
    person: Person;
    dragOver: string | undefined;
    dragOverLr: string | undefined;
    print: boolean;
    onDragStart: (e: any) => any;
    onDragOver: (e: any) => any;
    onDrop: (e: any) => any;
    onDragEnter: (e: any) => any;
    updatePerson: (person: Person) => void;
    updateTask: (task: Task) => void;
    onDelete: (person: Person) => void;
}

const PersonRow = ({ framework, person, task, dragOver, dragOverLr, onDragStart, onDragOver, onDrop, onDragEnter, updatePerson, updateTask, print, onDelete }: PersonRowProps) => {

    const assignment = task.assignments.find(a => a.personId === person.personId);

    return <tr key={"tr"+person.personId}>
        <td
            draggable
            id={"person-" + person.personId}
            className={"nowrap " + (dragOver === person.personId ? " drag-over-" + dragOverLr : "") + " " + ((assignment && assignment.description) ? "comment": "")}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            key={"r"+person.personId}
            title={(assignment && assignment.description)? assignment.description : ""}
        >
            <EditableText initialValue={person.name} title="Double-click to edit" onConfirm={(text) => {
                person.name = text;
                updatePerson(person);
            }} onDelete={() => { onDelete(person) }} 
            print={print} />
        </td>
        {
            framework.roles.map((role, i) => {
                
                return <SingleCell key={ "sngcel" + i} framework={framework} role={assignment ? assignment.roles & role : Roles.None} cellUpdated={(r) => {
                    if (assignment) {

                        task.assignments = task.assignments.map(a => {
                            if (a.personId === person.personId) {
                                const newrole = (a.roles & role) === role ? a.roles & ~role : a.roles | role;
                                if (framework.validateAssignment(newrole)) {
                                    return {
                                        personId: a.personId,
                                        roles: (a.roles & role) === role ? a.roles & ~role : a.roles | role
                                    } as Assignment
                                } else {
                                    return a;
                                }
                            } else {
                                return a;
                            }
                        })
                    } else {

                        task.assignments.push({ personId: person.personId, roles: role })
                    }
                    updateTask(task)
                }} />
            })

        }
        <td className={print ? "hide" : ""}>
            {/* {validation()} */}
        </td>
    </tr >;
}

export default PersonRow;