import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Task } from "../dataModel/dataModel";
import { DragActionProps } from "../dataModel/dragHelpers";
import Popup from "./Popup";

type TaskCellProps = {
    task: Task;
    print: boolean;
    updateTask: (task: Task) => void;
    onDelete: (task: Task) => void;
} & DragActionProps;

const TaskCell = ({ task, dragOver, dragOverLr, onDragStart, onDragOver, onDrop, onDragEnter, print, updateTask, onDelete }: TaskCellProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState(task.description);
    const [name, setName] = useState(task.name);
    const ref = useRef<HTMLInputElement>(null);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e !== null && e.target !== null) {
            setDescription((e.target as any).value);

        }
        e.stopPropagation();
    };
    const handleInputChange = (e: any) => {
        setName(e.target.value);
    };

    useEffect(() => {
        updateTask({ ...task, name, description })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, description]);

    useEffect(() => {
        if (ref && ref.current) ref.current.focus();
    }, [ref, isOpen])

    return <td
        draggable
        id={"task-" + task.taskId}
        className={"nowrap " + (dragOver === task.taskId ? "drag-over-" + dragOverLr : "") + (description ? " comment" : "")}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
    >
        <div className='editable-text' title={description}>
            <span onDoubleClick={togglePopup}>{task.name}</span>
        </div>
        <Popup isOpen={isOpen} closed={() => {
            togglePopup();
        }}>
            <h2>Edit task</h2>
            <div>
                <input ref={ref} type="text" value={name} style={{ width: "300px" }} onChange={handleInputChange} />
            </div>
            <h3>Comment</h3>
            <div>
                <textarea value={description} onChange={handleTextAreaChange} />
            </div>
            <button onClick={() => {
                togglePopup();
            }}>Close</button> {onDelete &&
                <i className="fi fi-br-trash" onClick={() => {togglePopup(); onDelete(task) }}></i>
            }
        </Popup>
        {/* <EditableText initialValue={task.name} title="Double-click to edit" print={print}
            onConfirm={(text) => {
                task.name = text;
                updateTask(task);
            }}
            onDelete={() => { onDelete(task) }}
        /> */}
    </td>
}

export default TaskCell;